import React from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useMatch,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Infos from "../pages/infos";
import { Story } from "../story";
import { Season, SeasonStart, SeasonOrder, SeasonStories } from "../season";

import Contact from "../pages/Contact";
import Family from "../pages/Family";
import Licence from "../pages/Licence";
import Credits from "../pages/Credits";

function AnimatedRoutes({ params }) {
  const location = useLocation();
  const match = useMatch("*");
  let animKey = location.pathname.split("/", 2)[1] || "start";
  // CHeck location if allowed (TO DO)
  // console.log(location, match);
  const allowedRoutes = [
    "infos",
    "about",
    "licence",
    "credits",
    "contact",
    "order",
    "s1",
    "s2",
    "s3",
  ];

  if (match.pathname === "/" || !allowedRoutes.includes(animKey))
    return <Navigate to={params.app_start} replace={true} />;
  /*
  - Season
    - SeasonStart
    - SeasonStories
    - SeasonDownload
    - SeasonOrder
  

  let element = useRoutes(
    [
      { path: "/", element: <Home /> },
      { path: "/home", element: <Home /> },
      { path: "/infos", element: <Infos /> },
      { path: "/contact", element: <Contact /> },
      {
        path: _t.season + ":seasonId",
        element: <Season />,
        children: [
          { index: true, element: <SeasonStart /> },
          {
            path: "ep:episodeId",
            element: <Story />,
            children: [
              { index: true, element: <StoryComics /> },
              { path: "desc", element: <StoryDescription /> },
              { path: "tips", element: <StoryTips /> },
              { path: "links", element: <StoryFurtherLinks /> },
              { path: "*", element: <StoryComics /> },
            ],
          },
          { path: "stories", element: <SeasonStories /> },
          { path: "order", element: <SeasonOrder /> },
          { path: "download", element: <SeasonDownload /> },
          { path: "*", element: <SeasonStart /> },
        ],
      },
    ],
    location
  );
  // we need a copy of the element to set the key
  const elementCopy = React.cloneElement(element, {
    key: location.pathname,
  });
  console.log(elementCopy);
  //return <AnimatePresence>{elementCopy}</AnimatePresence>;
*/
  return (
    <AnimatePresence initial={false}>
      <Routes key={animKey} location={location}>
        <Route path="/infos" element={<Infos {...{ params }} />} />
        <Route path="/about" element={<Family {...{ params }} />} />
        <Route path="/contact" element={<Contact {...{ params }} />} />
        <Route path="/licence" element={<Licence {...{ params }} />} />
        <Route path="/credits" element={<Credits {...{ params }} />} />
        <Route path="/s:seasonId" element={<Season {...{ params }} />}>
          <Route index element={<SeasonStart />} />
          <Route path="stories" element={<SeasonStories />} />
          <Route path="order" element={<SeasonOrder />} />
          <Route path="e:episodeId" element={<Story />} />
          <Route path="*" element={<SeasonStart />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
