import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";

const allowedRoutes = [
  "infos",
  "about",
  "licence",
  "credits",
  "contact",
  "order",
  "s1",
  "s2",
  "s3",
];

const Footer = ({ contact, lexicon, params }) => {
  const { pathname } = useLocation();

  if (!allowedRoutes.includes(pathname.replace(/\//g, ""))) {
    return <footer style={{ minHeight: "20vh" }} />;
  }

  return (
    <div className="footer-wrapper d-flex flex-column small text-secondary">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1518 37"
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          d="M214.722 15.5018C189.317 15.7525 178.12 17.0413 134.058 19.9635L101.908 18.3169C93.6823 17.7435 93.0767 16.9378 57.8119 17.7047C35.7773 18.1839 26.671 20.2309 23.7304 22.3049C21.0183 24.2178 11.6794 29.7296 5.10851 31.1817C2.13928 31.8379 1.98813 36.4133 0 37.5763C2.29336 37.5763 6.04705 37.4516 5.1078 37.5763H46.4068H1518C1517.99 36.1363 1512.89 27.8291 1498.67 25.2201C1484.45 22.6111 1458.96 14.8764 1459.7 13.5599C1460.43 12.2435 1464.9 10.8902 1449.92 7.24128C1434.94 3.5924 1399.04 0.935421 1384.84 0.290061C1370.63 -0.3553 1353.45 0.207006 1342.25 0.710328C1331.04 1.21365 1311.62 2.45262 1281.76 5.2348L1277.63 5.61946C1248.82 8.30783 1216.69 11.3046 1183.19 12.8844C1155.11 14.2089 1112.71 14.0163 1095.02 13.7544C1068.86 12.9216 1012.49 11.1124 996.345 10.5386C980.198 9.96476 957.237 10.619 947.775 11.0178C938.064 11.4191 914.46 12.4987 897.732 13.6064C876.821 14.991 826.793 19.1504 814.844 19.9229C802.894 20.6955 746.86 22.0339 731.914 22.0504C716.969 22.067 675.845 19.7234 649.674 18.1488C623.503 16.5741 565.197 14.7929 537.55 15.0657C509.902 15.3385 482.272 17.3131 462.108 18.6904C441.945 20.0676 388.162 22.4312 379.197 22.6506C370.232 22.87 355.281 22.3629 338.839 22.2633C322.398 22.1637 287.986 18.4447 280.505 17.602C273.024 16.7594 240.128 15.2511 214.722 15.5018Z"
          fill="#FEFEFE"
        />
      </svg>
      <footer>
        <Container className="max-w">
          <div className="grid">
            <div className="g-col-12">
              <h3 className="small">{lexicon.projectFrom}</h3>
            </div>
            <div className="g-col-12 g-col-md-8 pb-5">
              <object
                data={
                  process.env.REACT_APP_BASE_URL +
                  `assets/app/img/bund_${params.cultureKey}.svg`
                }
                type="image/svg+xml"
                style={{
                  width: "100%",
                  outline: "none",
                  border: "none",
                  maxWidth: "640px",
                }}
                aria-label="logo Bune"
              />
            </div>
            <div className="g-col-12 g-col-md-4 text-md-end">
              <a href="https://skppsc.ch">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "assets/app/img/SKPPSC_logo.svg"
                  }
                  alt="SKPPSC"
                  style={{
                    width: "100%",
                    maxWidth: "360px",
                    outline: "none",
                    border: "none",
                  }}
                />
              </a>
            </div>
          </div>
        </Container>

        <Container fluid className="bg-dark">
          <div className="d-flex align-items-end">
            <div>
              © {new Date().getFullYear()} {contact.org}
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
