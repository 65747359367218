import React from "react";
import PagesMotion from "../_utils/PagesMotion";
import Helmetor from "../_utils/Helmetor";
import { FamilyKids, FamilyParents } from "./FamilyParents";

const Family = ({ params }) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <>
      <Helmetor
        title={params.lexicon.familytitle}
        desc={params.family.desc}
        img={params.site_url + params.site_image}
        url={window.location.href}
      />
      <PagesMotion page="familypage">
        <h3 className="fs-2 title-box">
          <span className="dark">{params.lexicon.familytitle}</span>
        </h3>
        <div className="grid gap-0 text-center mx-auto">
          <div className="g-col-12 g-col-md-6">
            <FamilyParents family={params.family} />
          </div>
          <div className="g-col-12 g-col-md-6">
            <FamilyKids family={params.family} />
          </div>
        </div>
        <h3 className="fs-6 title-box mb-n4">
          <span className="dark rt-0">{params.family.title}</span>
        </h3>
        <div className="text-center panel-box mx-n3 py-5">
          <p style={{ whiteSpace: "pre-wrap" }}>{params.family.desc}</p>
        </div>
      </PagesMotion>
    </>
  );
};

export default Family;
