import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCards,
  Keyboard,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  HiFastForward,
  HiRewind,
  HiRefresh,
  HiOutlineInformationCircle,
  HiOutlineChatAlt2,
} from "react-icons/hi";

// Import Swiper styles

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";

import "./style.scss";
import { ButtonNext, ButtonPrev } from "../_utils/buttons";
// eslint-disable-next-line no-unused-vars
import Button from "react-bootstrap/Button";
//import Container from "react-bootstrap/Container";

const variants = {
  init: {
    scale: 0,
    opacity: 0.5,
    // filter: "brightness(.6)"
  },
  open: {
    scale: 1,
    opacity: 1,
    // filter: "brightness(1)",
    transition: { duration: 0.4, type: "tween" },
  },
  close: {
    scale: 0,
    opacity: 0.5,
    //  filter: "brightness(.6)",
    transition: { duration: 0.4, type: "tween" },
  },
};

const StoryComics = ({
  episode,
  seasonId,
  episodeId,
  swiperRef,
  params,
  setView,
  ...rest
}) => {
  return (
    <>
      <motion.div
        initial="init"
        animate="open"
        exit="close"
        variants={variants}>
        {/* Story controler */}
        <div className="d-flex justify-content-evenly align-items-center w-100">
          <div className="d-none d-md-flex z-index-1 ps-5">
            <ButtonPrev
              size="lg"
              onClick={() => swiperRef.current.swiper.slidePrev()}
            />
          </div>
          <EpisodeViewer {...{ episode, swiperRef, params, setView }} />

          <div className="d-none d-md-flex z-index-1 pe-5">
            <ButtonNext
              size="lg"
              onClick={() => swiperRef.current.swiper.slideNext()}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

const EpisodeViewer = ({ episode, swiperRef, params, setView }) => {
  let navigate = useNavigate();
  const lexicon = params.lexicon;
  return (
    <Swiper
      // install Swiper modules
      ref={swiperRef}
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectCards, Keyboard]}
      keyboard={{
        enabled: true,
      }}
      effect={"cards"}
      grabCursor={true}
      className="episode-swiper mb-5"
      initialSlide={1}
      // slidesPerView={3}
      // grid={{
      //   rows: 3,
      //   fill: "row",
      // }}
      //navigation
      pagination={{ el: ".story-pagination", clickable: true }}
      //scrollbar={{ draggable: true }}
      preventClicks={false}
      // resizeObserver={false}
      // autoHeight={true}
      onReachEnd={() => {
        navigate("/" + episode.navigation.next.uri, { state: "next" });
      }}
      onReachBeginning={() => {
        navigate("/" + episode.navigation.prev.uri, { state: "prev" });
      }}
      onTransitionEnd={(swiper) => {
        //console.log("transition ended", swiper);
        // make buttons clickable on slides
        swiper.visibleSlides[0].style.transform += "translateZ(1px)";
      }}>
      <SwiperSlide>
        <StartEndSlide />
      </SwiperSlide>
      <SwiperSlide className="imgTitle">
        <TitleSlide src={episode.titleImage} />
      </SwiperSlide>
      {episode.images.map((src, idx) => (
        <SwiperSlide key={`slide-episode-${idx}`}>
          <ImageSlide {...{ src, idx }} />
        </SwiperSlide>
      ))}

      {/* <SwiperSlide className="tips">
        <TipsSlide title={params.storiestipps} text={episode.introtext} />
      </SwiperSlide> */}
      <SwiperSlide className="tips ">
        <FurtherReadingsSlide {...{ swiperRef, lexicon, setView }} />
      </SwiperSlide>

      <SwiperSlide>
        <StartEndSlide />
      </SwiperSlide>

      <div slot="container-end" className="text-center mt-3">
        <div className="story-pagination"></div>
      </div>
    </Swiper>
  );
};

const FurtherReadingsSlide = ({ swiperRef, lexicon, setView }) => {
  return (
    <>
      <div className="px-3 py-4">
        <p style={{ whiteSpace: "pre-line" }}>
          {lexicon.story_furtherreading_intro}
        </p>
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="dark btn-comic-dark"
            style={{ filter: "none" }}
            onClick={() => setView("tips")}>
            <HiOutlineChatAlt2 className="fs-3 me-1" />
            {lexicon.storiestipps}
          </Button>
          <Button
            variant="dark btn-comic-dark"
            style={{ filter: "none" }}
            onClick={() => setView("infos")}>
            <HiOutlineInformationCircle className="fs-3 me-1" />
            {lexicon.storiesinfos}
          </Button>
        </div>
      </div>

      <div className="pt-5 py-4">
        <p style={{ whiteSpace: "pre-line" }}>
          {lexicon.story_furtherreading_next}
        </p>
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="outline-default"
            onClick={() => swiperRef.current.swiper.slideTo(0)}>
            <HiRewind className="fa-3x" />
            <span className="d-none d-md-inline ms-1">
              {lexicon.story_furtherreading_btn_prev}
            </span>
          </Button>
          <Button
            variant="outline-default"
            onClick={() => swiperRef.current.swiper.slideTo(1)}>
            <HiRefresh className="fa-3x" />
            <span className="d-none d-md-inline ms-1">
              {lexicon.story_furtherreading_btn_rewind}
            </span>
          </Button>
          <Button
            variant="outline-default"
            onClick={() => swiperRef.current.swiper.slideNext()}>
            <span className="d-none d-md-inline me-1">
              {lexicon.story_furtherreading_btn_next}
            </span>
            <HiFastForward className="fa-3x" />
          </Button>
        </div>
      </div>
    </>
  );
};

const ImageSlide = ({ src, idx }) => {
  return <img className="" src={src} alt="" />;
};

const StartEndSlide = () => {
  return <div />;
};
const TitleSlide = ({ src }) => {
  return <img className="w-100 h-100" src={src} alt="" />;
};

export default StoryComics;
