import React from "react";
import LicenceForm from "../contact/LicenceForm";
import PagesMotion from "../_utils/PagesMotion";

const Licence = ({ params }) => {
  return (
    <PagesMotion>
      {/* <h3>Welcome Licence</h3>
      <LicenceForm /> */}

      <LicenceForm {...{ params }} />
    </PagesMotion>
  );
};

export default Licence;
