import React from "react";
import { motion } from "framer-motion";
import PagesMotion from "../_utils/PagesMotion";
import Helmetor from "../_utils/Helmetor";

const Infos = ({ params }) => {
  const { menu, lexicon } = params;

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <>
      <Helmetor
        title={lexicon.orderdownloadtitle}
        desc={""}
        img={params.site_url + params.site_image}
        url={window.location.href}
      />
      <PagesMotion>
        <h3 className=" fs-2 title-box">
          <span className="dark">{lexicon.orderdownloadtitle}</span>
        </h3>
        <div className="grid mx-lg-n5">
          {menu.seasons.map((m, idx) => (
            <Panel key={idx} {...{ m, params, idx }} />
          ))}
        </div>
      </PagesMotion>
    </>
  );
};

const Panel = ({ m, params, idx }) => {
  const { lexicon } = params;
  const imgPath = `${params.site_url}assets/stories/${params.cultureKey}/${m.alt}/cover.jpg`;
  const pdfBasePath = `${params.site_url}${m.alt}/pdf`;

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5 + 0.1 * idx }}
      className="g-col-12 g-col-sm-6 g-col-md-4"
      style={{ filter: "drop-shadow(0 4px 7px rgba(0,0,0,.2))" }}>
      <div className="d-flex flex-column align-items-center ">
        <img
          src={imgPath}
          alt=""
          width="100%"
          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        />
        <div
          className="bg-white w-100 px-3"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            paddingBottom: "3rem",
          }}>
          <h4 className="title-box mt-n4 mb-4">
            <span className="dark">{m.text}</span>
          </h4>

          <ul className="fa-ul">
            <li>
              <h5>{lexicon.download}</h5>
            </li>
            <li>
              <i className="fa-li fa fa-file-pdf-o" />
              <a href={pdfBasePath}>{lexicon.pdf_light}</a>
            </li>
            <li>
              <i className="fa-li fa fa-file-pdf-o" />

              <a href={pdfBasePath + "-hd"}>{lexicon.pdf_hd}</a>
            </li>
            <li>
              <i className="fa-li fa fa-file-pdf-o" />

              <a href={pdfBasePath + "-print"}>{lexicon.pdf_print}</a>
            </li>
            <h5 className="mt-3">{lexicon.order}</h5>
            <li>
              <a href={`${params.site_url}${m.alt}/order-bbl`}>
                {lexicon.orderfree} <i className="fa-fw fa fa-external-link" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default Infos;
