import Container from "react-bootstrap/Container";

export const Section = ({ title, children, ...props }) => {
  return (
    <section {...props}>
      <Container className="max-w">
        {title && (
          <header>
            <h3>{title}</h3>
          </header>
        )}
        {children}
      </Container>
    </section>
  );
};

export const Box = ({ text }) => {
  return (
    <div className="g-col-3 g-col-md-1">
      <div className="comic-box py-5">
        <p>{text}</p>
      </div>
    </div>
  );
};
export const BubbleBox = ({ text, idx }) => {
  const str = idx & 1 ? "left" : "right";

  return (
    <div className="g-col-3 g-col-md-1">
      <div className={"bubble-box bubble-" + str}>
        <div>{text}</div>
      </div>
    </div>
  );
};
