import React from "react";
import { motion } from "framer-motion";
import PagesMotion from "../_utils/PagesMotion";
import Helmetor from "../_utils/Helmetor";

const stagVariants = {
  transition: { staggerChildren: 0.1 },
};

const Credits = ({ params }) => {
  const { credits } = params;
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <>
      <Helmetor
        title={credits.title}
        desc={""}
        img={params.site_url + params.site_image}
        url={window.location.href}
      />
      <PagesMotion>
        <h3 className="fs-2 title-box">
          <span className="dark">{params.lexicon.creditstitle}</span>
        </h3>

        <motion.div
          variants={stagVariants}
          transition="transition"
          className="grid text-center gap-0"
          style={{ "--bs-columns": 2 }}>
          {credits.credits.map((str, i) => (
            <CreditBox key={`credits-p-${i}`} idx={i}>
              <h3>{str.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: str.text }} />
            </CreditBox>
          ))}
        </motion.div>
      </PagesMotion>
    </>
  );
};

const CreditBox = ({ children, idx }) => {
  return (
    <div className="g-col-2 g-col-md-1">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.15 * idx } }}
        exit={{ opacity: 0, y: 100 }}
        className="comic-box py-5">
        {children}
      </motion.div>
    </div>
  );
};

export default Credits;
