import React from "react";
import { motion } from "framer-motion";
import { Link, useOutletContext } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Stern from "../svg/Stern";
import { TiShoppingCart } from "react-icons/ti";
import { GrDocumentPdf } from "react-icons/gr";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import Helmetor from "../_utils/Helmetor";

const menuVariants = {
  init: {},
  animate: {
    transition: { staggerChildren: 0.1, delayChildren: 1 },
  },
};

const buttonVariants = {
  init: { y: "30vh", opacity: 0.5 },
  animate: { y: 0, opacity: 1 },
};

const SeasonStart = () => {
  const { setOverlay, data, seasonId, params } = useOutletContext();

  React.useEffect(() => {
    setOverlay({});
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmetor
        title={data.longtitle + " " + data.pagetitle}
        desc={data.introtext}
        img={data.coverImage}
        url={window.location.href}
      />
      <Container>
        <div
          className="d-flex flex-column vh-100 justify-content-between align-items-center"
          style={{ paddingBottom: "12vh", paddingTop: "10vh" }}>
          {/* Titre */}
          <div className="position-relative text-center mt-n3 mt-md-2">
            <motion.div
              className="stern-start"
              initial={{ scale: 0 }}
              animate={{ scale: [0, 2, 0.8, 1.2, 1] }}
              transition={{ delay: 1, times: [0.5, 0.2, 0.1, 0.05] }}>
              <Stern
                color={`var(--s${seasonId}-accent)`}
                text={data.menutitle}
              />
            </motion.div>
            <motion.img
              initial={{ y: "-40vh", opacity: 0 }}
              animate={{ y: "-1vh", opacity: 1 }}
              transition={{
                type: "spring",
                bounce: 0.4,
                duration: 1,
                delay: 0.5,
              }}
              src={data.titleImage}
              alt=""
              style={{
                maxWidth: "720px",
                maxHeight: "15vh",
                minHeight: "170px",
                filter: "drop-shadow(5px 5px 2px #0005)",
              }}
              width="90%"
            />
          </div>

          <StartMenu params={params} />
        </div>
      </Container>
    </>
  );
};

const StartMenu = ({ params }) => {
  const { lexicon } = params;
  return (
    <motion.div
      className="start-buttons px-3 pb-4 pb-sm-0 mb-5 mb-sm-0 max-w-sm d-flex gap-3"
      initial="init"
      animate="animate"
      variants={menuVariants}>
      <motion.div
        className="me-auto"
        variants={buttonVariants}
        whileTap={{ scale: 0.9 }}>
        <Button
          size="lg"
          variant="primary text-white"
          as={Link}
          to="../stories">
          <BsFillGrid3X3GapFill className="d-none d-md-inline-block fa-lg me-3" />
          <span>{lexicon.menustories}</span>
        </Button>
      </motion.div>
      <motion.div variants={buttonVariants} whileTap={{ scale: 0.9 }}>
        <Button size="lg" variant="light" as={Link} to="../order">
          <TiShoppingCart className=" fa-lg me-md-2" />
          <span className="d-none d-md-inline">{lexicon.order}</span>
        </Button>
      </motion.div>
      <motion.div variants={buttonVariants} whileTap={{ scale: 0.9 }}>
        <Button size="lg" variant="light" as={Link} to="../order">
          <GrDocumentPdf className=" fa-lg me-md-2" />
          <span className="d-none d-md-inline">{lexicon.download}</span>
        </Button>
      </motion.div>
    </motion.div>
  );
};

export default SeasonStart;
