import React from "react";
import { useQuery } from "@tanstack/react-query";
import fetch from "../_utils/fetch";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { AnimatePresence } from "framer-motion";

import { useParams, useOutlet, useLocation } from "react-router-dom";

import "./index.scss";

import SeasonBackground from "./SeasonBackground";
import SeasonOverlay from "./SeasonOverlay";

const Season = ({ params }) => {
  const [overlay, setOverlay] = React.useState({});
  const { seasonId } = useParams();
  // const overlayRef = React.useRef({});

  const query = useQuery([`s${seasonId}`], () => fetch(`seasons/s${seasonId}`));

  if (query.isLoading) {
    return <Loading />;
  }
  if (query.isError) {
    return <>Error...</>;
  }

  const data = query.data;
  return (
    // Animate like a page
    <>
      {/* background image of hero */}
      <div
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          // marginTop: "-100vh",
        }}>
        <SeasonBackground
          data={data}
          overlay={overlay}
          key={`season-backgrounds${data.id}`}
        />

        <SeasonOverlay data={data} overlay={overlay} />
      </div>

      <article className="hero-wrapper position-relative pb-5">
        {/* hero content scrollable */}
        <Content {...{ data, seasonId, overlay, setOverlay, params }} />
      </article>
    </>
  );
};

const Content = ({ data, seasonId, setOverlay, overlay, params }) => {
  const { state, ...location } = useLocation();
  // const match = useMatch(`/s${seasonId}/stories`);
  // console.log("the location state from season ", state);
  // const hasOverlay = Object.keys(overlay).length > 0 ? true : false;
  // if useParams = episode id key = episode
  // const { episodeId } = useParams();
  // const motionKey = episodeId
  //   ? "episode-view"
  //   : match
  //   ? "season-stories-view"
  //   : "season-default-view";
  const { trackPageView } = useMatomo();
  // on first load
  React.useEffect(() => {
    const episodes = data.episodes;
    trackPageView();

    const imagesArray = Object.keys(episodes).reduce((r, key) => {
      const e = episodes[key];
      r.push(e.titleImage, e.titleImageBackground, e.titleImageShadow);
      e.images.forEach((imgSrc) => r.push(imgSrc));
      return r;
    }, []);
    cacheImages(imagesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const outlet = React.cloneElement(
    useOutlet({
      data,
      seasonId,
      overlay,
      setOverlay,
      params,
    }),
    {
      key: `season-wrapper-${location.pathname}`,
    }
  );

  return (
    <>
      <div className="container-main position-relative mx-auto">
        <AnimatePresence>{outlet}</AnimatePresence>
      </div>
    </>
  );
};

const Loading = () => {
  return (
    <div className="vh-100 vw-100 position-fixed top-0 start-0 bg-dark d-flex align-items-center justify-content-center opacity-25">
      <div
        className="spinner-border text-light"
        role="status"
        style={{ width: "3rem", height: "3rem" }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });
  await Promise.all(promises);
};

export default Season;
