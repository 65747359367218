import React from "react";
import ContactForm from "../contact/ContactForm";
import PagesMotion from "../_utils/PagesMotion";

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BiWorld } from "react-icons/bi";
import Helmetor from "../_utils/Helmetor";

const Contact = ({ params }) => {
  const { contact, lexicon } = params;
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <>
      <Helmetor
        title={lexicon.contactus}
        desc={
          contact.org +
          ", " +
          contact.suborg +
          " - " +
          contact.street +
          ", " +
          contact.zip +
          " " +
          contact.city +
          " - " +
          contact.phone +
          " - " +
          contact.url
        }
        img={params.site_url + params.site_image}
        url={window.location.href}
      />
      <PagesMotion>
        <div className="grid">
          <div className="g-col-12 g-col-md-4 pt-md-5">
            <h3 className="fs-6 title-box mb-n4">
              <span className="dark rt-0">{lexicon.contactus}</span>
            </h3>
            <div className="panel-box mx-n3 py-5">
              <div className="grid mb-5 px-3">
                <div className="g-col-12">
                  <address>
                    <strong>{contact.org}</strong>
                    <br />
                    {contact.suborg} <br />
                    <br />
                    {contact.street} <br />
                    {contact.zip} {contact.city} <br />
                    <br />
                    <HiOutlinePhone className="fa-lg  fa-fw" /> {contact.phone}{" "}
                    <br />
                    <BiWorld className="fa-lg  fa-fw" />{" "}
                    <a href={contact.url} target="_blank" rel="noreferrer">
                      {contact.website}
                    </a>{" "}
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="g-col-12 g-col-md-8">
            <h3 className="fs-6 title-box mb-n4">
              <span className="dark rt-0">
                <HiOutlineMail className="fa-lg fa-fw" /> {lexicon.sendmail}
              </span>
            </h3>
            <div className="panel-box mx-n3 py-5">
              <ContactForm params={params} />
            </div>
          </div>
        </div>
      </PagesMotion>
    </>
  );
};
export default Contact;
