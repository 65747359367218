import React from "react";
import { motion } from "framer-motion";

const variants = {
  visible: (obj) => ({
    opacity: obj.o,
    translateX: `${obj.x}px`,
    translateY: `${obj.y}px`,
    scale: obj.s,
    rotate: `${obj.r}deg`,
    transition: {
      type: "spring",
      stiffness: 90,
      mass: 0.8,
      damping: 10,
      duration: 0.7,
    },
  }),
  init: {
    opacity: 0,
    translateX: 0,
    translateY: 0,
    scale: 0,
    rotate: "-80deg",
    transition: { duration: 0.2 },
  },
};

const shadowCustoms = [
  { o: 0.15, s: 1.6, r: -18, x: -300, y: -200 },
  { o: 0.15, s: 1, r: -20, x: -300, y: 100 },
  { o: 0.15, s: 1.3, r: 13, x: 290, y: -250 },
  { o: 0.15, s: 0.5, r: -11, x: -280, y: -30 },
  { o: 0.15, s: 0.5, r: 18, x: 400, y: -100 },
  { o: 0.15, s: 1.5, r: -8, x: -50, y: 40 },
  { o: 0.15, s: 1, r: 5, x: 300, y: 100 },
  { o: 0.15, s: 0.8, r: 8, x: 190, y: -50 },
  { o: 0.15, s: 0.8, r: 1, x: 100, y: 150 },
  { o: 0.15, s: 0.5, r: -18, x: -420, y: -10 },
  { o: 0.15, s: 1, r: -1, x: 30, y: 240 },
];

const ShadowsBackground = ({ overlay }) => {
  return (
    <svg
      width="4000"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      viewBox="-2000 -400 4000 800">
      {/* <style>{`
          @media (max-width: 575.98px)  {
            #e-shadows {
              transform: translateY(50px) scale(.9); 
            }
          }
          `}</style> */}
      <g id="e-shadows">
        {shadowCustoms.map((customObject, idx) => {
          return (
            <motion.use
              key={`svg_shadow_img_${overlay.colorVar}${idx}`}
              initial="init"
              animate="visible"
              exit="init"
              variants={variants}
              custom={customObject}
              xlinkHref="#shadowImg"
            />
          );
        })}
      </g>
      <defs>
        <image
          id="shadowImg"
          xlinkHref={overlay.imgUrl}
          width="250"
          height="250"
          x="-125"
          y="-125"></image>
      </defs>
    </svg>
  );
};

export default ShadowsBackground;
