const rootcolors = {
  white: "#ffffff",
  default: "#efefef",
  menutop: "#EFCA3C",
  s1: "#3bb5d0",
  s2: "#d0b783",
  s3: "#89af70",
  s1stories: "#3bb5d0",
  s2stories: "#d0b783",
  s3stories: "#89af70",
  // season s1" *,"
  s1e1: "#a2c9ec",
  s1e2: "#cc9cc5",
  s1e3: "#f19569",
  s1e4: "#4eb791",
  s1e5: "#6a9bcd",
  s1e6: "#f7ed73",
  s1e7: "#9cca9c",
  s1e8: "#f7cbd0",
  s1e9: "#fcca6b",
  s1e10: "#9d6b6c",
  s1e11: "#ed7171",
  s1e12: "#c9ca65",
  s1e13: "#facb70",
  s1e14: "#d2e9f1",
  s1e15: "#4b4b4b",
  // season s2" *,"
  s2e1: "#c83132",
  s2e2: "#9ac4e4",
  s2e3: "#50b792",
  s2e4: "#3e6fb4",
  s2e5: "#666a7d",
  s2e6: "#e7aacb",
  s2e7: "#f39566",
  s2e8: "#c498c2",
  s2e9: "#fbec5d",
  s2e10: "#b3abd2",
  s2e11: "#f7f195",
  s2e12: "#b79d8e",
  // season" s3",
  s3e1: "#705c81",
  s3e3: "#73ac8f",
  s3e2: "#fc696a",
  s3e4: "#374234",
  s3e5: "#4c5c75",
  s3e6: "#5ec4ec",
  s3e7: "#e67150",
  s3e8: "#e24c3d",
  s3e9: "#d6c66f",
  s3e10: "#b7cfbc",
  s3e11: "#cc3333",
  s3e12: "#fda0ec",
};

export default rootcolors;
