import React from "react";
import { motion } from "framer-motion";
import { Section } from "./helpers";

const PagesMotion = ({ children, page }) => {
  return (
    // <div
    //   className="container-main position-relative mx-auto "
    //   style={{ backgroundColor: "var(--bs-gray-300)" }}>
    //   <Section>{children}</Section>
    // </div>
    <motion.div
      key={page ?? "default"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{
        opacity: 0,
        position: "absolute",
        transition: { duration: 0.25 },
      }}>
      <div className="container-main page-bg position-relative mx-auto no-overflow-wrapper ">
        <Section>{children}</Section>
      </div>
    </motion.div>
  );
};

export default PagesMotion;
