import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import fetch from "./components/_utils/fetch";

import AnimatedRoutes from "./components/_utils/AnimatedRoutes";
import CookieConsent from "react-cookie-consent";
import Navbar from "./components/menu/Navbar";
import Footer from "./components/_utils/Footer";

// language management
const lang =
  document.documentElement.lang ?? process.env.REACT_APP_DEFAULT_LANG;

function App() {
  const { status, data } = useQuery(["params"], () => fetch("params"));
  if (status === "loading") return "loading....";
  if (status === "error") return "error";

  return (
    <Router basename={"/" + lang}>
      <Navbar params={data} />
      <main className="main-wrapper w-100 h-100 min-vh-100">
        <AnimatedRoutes params={data} />
      </main>
      <Footer lexicon={data.lexicon} contact={data.contact} params={data} />
      <CookieConsent
        buttonText={data.lexicon.cookies_warning_button}
        cookieName="wbstrsCookieConsent"
        style={{
          height: "36vh",
          textAlign: "center",
          whiteSpace: "pre-line",
          zIndex: 10000,
        }}
        buttonWrapperClasses="container pb-5"
        contentClasses="mx-auto max-w-sm pt-5 px-3"
        buttonClasses="btn btn-lg btn-warning text-uppercase">
        {data.lexicon.cookies_warning_text}
      </CookieConsent>
    </Router>
  );
}

export default App;
