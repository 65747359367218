import React from "react";
import ShadowsBackground from "./ShadowsBackground";

import { AnimatePresence } from "framer-motion";
import Helmetor from "../_utils/Helmetor";

const SeasonOverlay = ({ data, overlay }) => {
  const { colorVar } = overlay;

  const themeColor = colorVar ? colorVar.replace(/-|w|color/g, "") : data.alias;

  // console.log(themeColor);

  // init transparent
  // episodes
  // console.log("hallo image url? ", imgUrl);
  return (
    <>
      <Helmetor colorVar={colorVar} />

      <div
        className="position-absolute vh-100 vw-100 d-flex justify-content-center"
        style={{ overflow: "hidden" }}>
        <AnimatePresence exitBeforeEnter>
          <div
            key={"backgroundShadows" + themeColor}
            className="season-overlay-frame">
            <ShadowsBackground overlay={overlay} />
          </div>
        </AnimatePresence>
        {/* {colorVar && (
          <div
            className="season-overlay-frame vw-100 vh-100"
            style={{
              backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/assets/app/img/bg-grey.png')`,
              backgroundRepeat: "repeat",
              mixBlendMode: "multiply",
              backgroundBlendMode: "multiply",
            }}
          />
        )} */}

        <div
          className="season-overlay-frame vw-100 vh-100"
          style={{
            backgroundColor: colorVar ? `var(${colorVar})` : `#fff0`,
            mixBlendMode: "color",
            backgroundBlendMode: "color",
            //transition: "1s background-color",
          }}></div>
      </div>
    </>
  );
};

export default SeasonOverlay;
