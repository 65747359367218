import React from "react";
import { motion } from "framer-motion";
import {
  useParams,
  useLocation,
  useOutletContext,
  Link,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import StoryComics from "./StoryComics";
import StoryMenu from "./StoryMenu";

import Button from "react-bootstrap/Button";
import { Section, Box, BubbleBox } from "../_utils/helpers";
import Helmetor from "../_utils/Helmetor";
import {
  HiFastForward,
  HiRewind,
  HiRefresh,
  HiOutlineInformationCircle,
  HiOutlineChatAlt2,
} from "react-icons/hi";

const parentVariants = {
  init: (dir) => ({
    opacity: !dir ? 0 : 0.7,
    //z: 0,
  }),
  open: {
    //z: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.4,
      ease: "easeIn",
    },
  },
  close: (dir) => ({
    position: "absolute",
    // z: 1,
    opacity: !dir ? 0 : 0.7,
    transition: { type: "tween", duration: 0.4, ease: "easeOut" },
  }),
};

const heroVariants = {
  init: (dir) => ({
    x: dir === "prev" ? "-100vw" : dir === "next" ? "100vw" : 0,
  }),
  open: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.4,
      ease: "easeIn",
    },
  },
  close: (dir) => ({
    x: dir === "prev" ? "100vw" : dir === "next" ? "-100vw" : 0,
    transition: { type: "tween", duration: 0.4, ease: "easeOut" },
  }),
};

const additionalVariants = {
  init: {
    //y: 100,
    scale: 0,
    opacity: 0,
  },
  open: {
    //y: 0,
    scale: 1,
    opacity: 1,
    transition: { duration: 0.5 },
  },
  close: {
    //y: 100,
    scale: 0,
    opacity: 0,
    transition: { type: "tween" },
  },
};

const gridImageVariants = {
  init: {
    scale: 0,
    //opacity: 0,
  },
  open: {
    scale: 1,
    // opacity: 1,
    // transition: { duration: 0.5 },
  },
  close: {
    //y: 100,
    scale: 0,
    //  opacity: 0,
    // transition: { type: "tween" },
  },
};

function Story() {
  const { seasonId, episodeId } = useParams();
  const { state, pathname } = useLocation();

  const outletContext = useOutletContext();
  const episode = outletContext.data.episodes[`e${episodeId}`];

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    outletContext.setOverlay({
      imgUrl: episode.titleImageShadow,
      colorVar: `--w-s${seasonId}e${episodeId}`,
    });
    // outletContext.overlayRef.current = {
    //   imgUrl: episode.titleImageShadow,
    //   colorVar: `--w-s${seasonId}e${episodeId}`,
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // set currentEpisode

  return (
    <motion.div
      className="story-wrapper top-0 w-100"
      initial="init"
      animate="open"
      exit="close"
      variants={parentVariants}
      custom={state}
      style={{
        originY: 0,
      }}>
      <Content
        {...{ episode, seasonId, state, episodeId, outletContext, pathname }}
      />
    </motion.div>
  );
}

const Content = ({
  episode,
  data,
  state,
  seasonId,
  episodeId,
  outletContext,
  pathname,
}) => {
  const [view, setView] = React.useState("slider");
  const swiperRef = React.useRef(null);
  const _l = outletContext.params.lexicon;

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [view]);

  return (
    <>
      <Helmetor
        title={episode.longtitle}
        desc={episode.introtext}
        img={episode.titleImage}
        url={window.location.href}
      />
      <Container
        className="max-w"
        style={{ paddingTop: "4.8rem", paddingBottom: ".5rem" }}>
        <motion.span variants={additionalVariants}>
          {episode.pagetitle}: {episode.longtitle}
        </motion.span>
      </Container>
      {view === "slider" && (
        <NoOverflow>
          {" "}
          <motion.div
            className="sectionHero"
            variants={heroVariants}
            custom={state}>
            <section
              className="max-w mx-auto"
              style={{
                minHeight: "75vh",
                paddingTop: 0,
              }}>
              <StoryComics
                {...{
                  episode,
                  seasonId,
                  episodeId,
                  swiperRef,
                  setView,
                  ...outletContext,
                }}
              />
            </section>
          </motion.div>
        </NoOverflow>
      )}

      {view === "grid" && (
        <AdditionalContent key="additional-grid">
          <div className="text-center bg-white py-3 px-2">
            <div
              className="grid text-center gap-2 px-lg-5"
              style={{ "--bs-columns": 3 }}>
              {episode.images.map((src, idx) => (
                <div className="g-col-3 g-col-sm-1" key={`image-grid-${idx}`}>
                  <motion.div
                    key={`image-comic-${idx}`}
                    variants={gridImageVariants}>
                    <img className="img-comics w-100" src={src} alt="" />
                  </motion.div>
                </div>
              ))}
            </div>
            <FurtherReadingsSlide lexicon={_l} {...{ setView, episode }} />
          </div>
        </AdditionalContent>
      )}
      {view === "tips" && (
        <AdditionalContent key="additional-tips">
          <div>
            <h3 className="title-box">
              <span className="dark">{_l.storiestipps}</span>
            </h3>
            <div
              className="grid text-center gap-0"
              style={{ "--bs-columns": 2 }}>
              {episode.introtext
                .split("\n")
                .filter((str) => str.trim() !== "")
                .map((str, i) => (
                  <BubbleBox idx={i} key={`introtext-p-${i}`} text={str} />
                ))}
            </div>
          </div>
        </AdditionalContent>
      )}
      {view === "infos" && (
        <>
          <AdditionalContent id="infos">
            <div>
              <h3 className="title-box">
                <span className="rtleft dark">{_l.storiesinfos}</span>
              </h3>
              <div
                className="grid text-center gap-0"
                style={{ "--bs-columns": 2 }}>
                {episode.description
                  .split("\n")
                  .filter((str) => str.trim() !== "")
                  .map((str, i) => (
                    <Box key={`description-p-${i}`} text={str} />
                  ))}
              </div>
            </div>
          </AdditionalContent>
          <AdditionalContent>
            {episode.links && (
              <div className="p-lg-5">
                <h3 className="title-box">
                  <span className="dark">{_l.storieslinks}</span>
                </h3>
                <div
                  className="grid text-center gap-2"
                  style={{ "--bs-columns": 2 }}>
                  {episode.links.map((link, i) => (
                    <div
                      key={`stories-link-box-${i}`}
                      className="g-col-3 g-col-md-1 bg-white p-3">
                      <p className="fw-bold">{link.title}</p>
                      <p>{link.text}</p>
                      <p className="small lh-sm">
                        <a href={link.url}>
                          {link.urlTitle === "" && link.url}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </AdditionalContent>
        </>
      )}
      <StoryMenu {...{ episode, swiperRef, view, setView, _l }} />
    </>
  );
};

const AdditionalContent = ({ children, ...props }) => {
  return (
    <NoOverflow {...props}>
      <motion.div variants={additionalVariants} style={{ originY: 0.3 }}>
        <Section style={{ paddingTop: "12px" }}>{children}</Section>
      </motion.div>
    </NoOverflow>
  );
};

const NoOverflow = ({ children, ...props }) => {
  return (
    <div className="no-overflow-wrapper" {...props}>
      {children}
    </div>
  );
};

const FurtherReadingsSlide = ({ setView, lexicon, episode }) => {
  const nav = episode.navigation;

  return (
    <>
      <div className="px-3 py-4">
        <p style={{ whiteSpace: "pre-line" }}>
          {lexicon.story_furtherreading_intro}
        </p>
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="outline-dark"
            style={{ filter: "none" }}
            onClick={() => setView("tips")}>
            <HiOutlineChatAlt2 className="fs-3 me-1" />
            {lexicon.storiestipps}
          </Button>
          <Button
            variant="outline-dark"
            style={{ filter: "none" }}
            onClick={() => setView("infos")}>
            <HiOutlineInformationCircle className="fs-3 me-1" />
            {lexicon.storiesinfos}
          </Button>
        </div>
      </div>

      <div className="pt-5 py-4">
        <p style={{ whiteSpace: "pre-line" }}>
          {lexicon.story_furtherreading_next}
        </p>
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="outline-default"
            as={Link}
            to={{ pathname: "/" + nav.prev.uri, state: "prev" }}>
            <HiRewind className="fa-3x" />
            <span className="d-none d-md-inline ms-1">
              {lexicon.story_furtherreading_btn_prev}
            </span>
          </Button>
          <Button variant="outline-default" onClick={() => setView("slider")}>
            <HiRefresh className="fa-3x" />
            <span className="d-none d-md-inline ms-1">
              {lexicon.story_furtherreading_btn_rewind}
            </span>
          </Button>
          <Button
            variant="outline-default"
            as={Link}
            to={{ pathname: "/" + nav.next.uri, state: "next" }}>
            <span className="d-none d-md-inline me-1">
              {lexicon.story_furtherreading_btn_next}
            </span>
            <HiFastForward className="fa-3x" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Story;
