/* eslint-disable import/no-anonymous-default-export */
export default async function (...args) {
  console.log("fetch api called", args);
  const root_url = "https://dev.websters.swiss/api/";
  const doclang = document.documentElement.lang;
  const lang = doclang === "[[++cultureKey]]" ? "en" : doclang;

  const endPoint = args[0];
  const properties = "?ctx=" + lang;

  const res = await fetch(root_url + endPoint + properties);
  const json = await res.json();
  return await json.data;
}
