import React from "react";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import { InputText, InputTextArea } from "./contact_utils";
import Button from "react-bootstrap/Button";
import { RiMailCheckLine } from "react-icons/ri";

const ContactForm = ({ params }) => {
  const [status, setStatus] = React.useState("form");
  const formRef = React.useRef(null);
  const _l = params.lexicon;

  React.useEffect(() => {
    // console.log("form Ref", new FormData(formRef.current));
    if (status === "sent") window.scrollTo(0, 0);
  }, [status]);

  const onSubmit = async (e) => {
    if (!formRef.current) return null;
    const formData = new FormData(formRef.current);
    console.log("submittin", formRef.current.name);
    formData.append("formName", formRef.current.name);
    formData.append("ctx", params.cultureKey);
    return await fetch(process.env.REACT_APP_API_URL + "/formit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    // alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("sending");

    onSubmit(e).then((res) => {
      if (res.code === 200) setStatus("sent");
      else setStatus("error");
    });
  };

  return (
    <>
      {(status === "form" || status === "sending") && (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          name="contact-form"
          className="my-5">
          <fieldset disabled={status === "sending"}>
            <InputText
              name="firstname"
              label={_l.firstname + " & " + _l.lastname}
              defaultValue="Max"
            />

            <InputText
              name="email"
              label={_l.email}
              defaultValue="max@websters.swiss"
              type="email"
            />
            <InputTextArea
              name="message"
              label={_l.yourmessage}
              defaultValue=""
            />
            <Button
              size="lg"
              variant="primary text-white mb-5"
              type="submit"
              name="formtype"
              value="licence"
              disabled={status === "sending"}>
              {status === "sending" ? _l.sending : _l.submit}
            </Button>
          </fieldset>
        </Form>
      )}
      {status === "sent" && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="text-center display-1 bg-success p-3 d-flex align-items-center justify-content-center text-white my-5">
            <RiMailCheckLine />
          </div>
          <p style={{ whiteSpace: "pre-wrap" }}>{_l.contactSent}</p>
        </motion.div>
      )}
      {status === "error" && (
        <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
          {_l.formError}
        </p>
      )}
    </>
  );
};

export default ContactForm;
