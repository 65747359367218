import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

import { RiGridFill } from "react-icons/ri";
import { BiCarousel } from "react-icons/bi";
// import { HiOutlineChatAlt2, HiOutlineInformationCircle } from "react-icons/hi";
import { HiOutlineInformationCircle, HiOutlineChatAlt2 } from "react-icons/hi";
import { TbArrowBigLeftLine } from "react-icons/tb";

const buttonVariants = {
  init: {
    y: "-10vh",
    //z: -10,
    opacity: 0,
  },
  open: {
    y: 0,
    // z: 0,
    opacity: 1,
    transition: { type: "tween", delay: 0.6 },
  },
  close: {
    y: "25vh",
    opacity: 0,
    transition: { type: "tween", duration: 0.2 },
  },
};

const StoryMenu = ({ swiperRef, episode, view, setView, _l }) => {
  const changeView = (e) => {
    // console.log("event", e);
    // view === "grid" ? setView("slider") : setView("grid");
    view === "slider" ? setView("grid") : setView("slider");
  };

  return (
    <motion.div
      className="fixed-bottom d-flex justify-content-center gap-2 pt-3"
      variants={buttonVariants}
      style={{ paddingBottom: "5vh" }}>
      <motion.span whileTap={{ scale: 0.9 }}>
        <Button
          as={Link}
          variant="dark btn-comic-dark"
          to={`/${episode.navigation.up.uri}/stories`}>
          <TbArrowBigLeftLine className="fs-4" />
          <span className="d-none d-md-inline ms-2">{_l.menustories}</span>
        </Button>
      </motion.span>
      <motion.span whileTap={{ scale: 0.9 }}>
        <Button variant="dark btn-comic-dark" onClick={changeView}>
          {view === "slider" ? (
            <RiGridFill className="fs-4" />
          ) : (
            <BiCarousel className="fs-4" />
          )}
          <span className="d-none d-md-inline ms-2">{_l.menuview}</span>
        </Button>
      </motion.span>
      <motion.span whileTap={{ scale: 0.9 }}>
        <Button variant="dark btn-comic-dark" onClick={() => setView("tips")}>
          <HiOutlineChatAlt2 className="fs-4" />
          <span className="d-none d-md-inline ms-2">{_l.storiestipps}</span>
        </Button>
      </motion.span>
      <motion.span whileTap={{ scale: 0.9 }}>
        <Button variant="dark btn-comic-dark" onClick={() => setView("infos")}>
          <HiOutlineInformationCircle className="fs-4" />
          <span className="d-none d-md-inline ms-2 text-uppercase">
            {_l.storiesinfos}
          </span>
        </Button>
      </motion.span>
    </motion.div>
  );
};

export default StoryMenu;
