import React from "react";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import { Section } from "../_utils/helpers";
import { FaRegFilePdf } from "react-icons/fa";
import { HiExternalLink } from "react-icons/hi";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Helmetor from "../_utils/Helmetor";

const SeasonOrder = () => {
  const { trackPageView } = useMatomo();

  const { params, seasonId } = useOutletContext();
  const { site_url, lexicon } = params;

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmetor
        title={params.download.title + " + " + params.order.title}
        desc={params.download.text}
        img={params.site_url + params.site_image}
        url={window.location.href}
      />

      <Section>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}>
          <div className="grid w-100 mt-5" style={{ "--bs-columns": 2 }}>
            <Panel
              title={params.order.title}
              text={params.order.text}
              buttons={[
                {
                  url: `${site_url}s${seasonId}/order-bbl`,
                  title: lexicon.orderfree,
                  icon: "external",
                },
              ]}
            />
            <Panel
              title={params.download.title}
              text={params.download.text}
              buttons={[
                {
                  url: `${site_url}s${seasonId}/pdf`,
                  title: lexicon.pdf_light,
                  icon: "pdf",
                },
                {
                  url: `${site_url}s${seasonId}/pdf-hd`,
                  title: lexicon.pdf_hd,
                  icon: "pdf",
                },
                {
                  url: `${site_url}s${seasonId}/pdf-print`,
                  title: lexicon.pdf_print,
                  icon: "pdf",
                },
              ]}
            />
          </div>
        </motion.div>
      </Section>
    </>
  );
};

const Panel = ({ title, text, buttons, icon }) => {
  const { trackEvent } = useMatomo();

  const handleOnClick = (e) => {
    // Track click on button
    //console.log("click event", e);
    trackEvent({ category: "downloads", action: e.pathname || "pdf" });
  };
  return (
    <div className="g-col-2 g-col-md-1">
      <div
        className="panel-box py-5"
        style={{ filter: "drop-shadow(0 4px 11px rgba(0,0,0,.4))" }}>
        <h3>{title}</h3>
        <p style={{ whiteSpace: "pre-line" }}>{text}</p>
        <div className="d-flex flex-column align-items-center gap-3 mt-5">
          {buttons.map((b, idx) => (
            <a
              key={"panel-btn" + idx}
              href={b.url || "#"}
              className="btn btn-dark"
              onClick={handleOnClick}
              target="_blank"
              rel="noreferrer">
              {b.icon === "pdf" && <FaRegFilePdf className="fa-lg" />} {b.title}{" "}
              {b.icon === "external" && <HiExternalLink className="fa-lg" />}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SeasonOrder;
