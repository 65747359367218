import Form from "react-bootstrap/Form";
import TextareaAutosize from "react-textarea-autosize";

export function InputText({ name, label, defaultValue, type, handleChange }) {
  return (
    <Form.Group className="mb-3" controlId={"inputField" + name}>
      <Form.Label>{label}</Form.Label>

      <Form.Control
        type={type ?? "text"}
        name={name}
        placeholder={defaultValue}
        // defaultValue={defaultValue}
        onChange={handleChange}
        size="lg"
        required
      />
    </Form.Group>
  );
}

export function InputTextArea({ name, label, defaultValue, handleChange }) {
  return (
    <Form.Group label={label} className="mb-3" controlId={"inputField" + name}>
      <Form.Label>{label}</Form.Label>

      <Form.Control
        size="lg"
        as={TextareaAutosize}
        name={name}
        // rows={5}
        minRows={5}
        // placeholder={ph}
        defaultValue={defaultValue}
        onChange={handleChange}
      />
    </Form.Group>
  );
}

export function InputSelect({ name, label, defaultValue, handleChange }) {
  return (
    <Form.Group label={label} className="mb-3" controlId={"inputField" + name}>
      <Form.Select
        name={name}
        // placeholder={field}
        defaultValue={defaultValue}
        onChange={handleChange}>
        <option> Default select </option>{" "}
      </Form.Select>
    </Form.Group>
  );
}
