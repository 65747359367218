import React from "react";
import { motion } from "framer-motion";
import { Link, useOutletContext } from "react-router-dom";
import { Section } from "../_utils/helpers";
import Helmetor from "../_utils/Helmetor";

const container = {
  init: false,
  show: {
    transition: {
      // duration: 0.4,
      //delayChildren: 0.15,
      staggerChildren: 0.02,
      when: "beforeChildren",
    },
  },
  close: {
    transition: {
      duration: 0.4,
      //when: "afterChildren",
    },
  },
};

const item = {
  init: { scale: 0, zIndex: 1 },
  show: {
    scale: 1,
    transition: { type: "spring", bounce: 0.6 },
  },
  close: { scale: 0, transition: { type: "tween", duration: 0.2 } },
};

const divVariants = {
  init: {
    scale: 0,
    // y: "50vh",
    opacity: 0,
  },
  show: {
    scale: 1,
    // y: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.4 },
  },
  close: {
    scale: 0,
    //y: "50vh",
    //x: "-100%",
    opacity: 0.2,
    position: "absolute",
    transition: { ease: "easeInOut", duration: 0.4, when: "beforeChildren" },
  },
};

const SeasonStories = () => {
  const { setOverlay, seasonId, data } = useOutletContext();

  React.useEffect(() => {
    setOverlay({ colorVar: `--s${seasonId}-color` });
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmetor
        title={data.longtitle + " " + data.pagetitle}
        desc={data.introtext}
        img={data.coverImage}
        url={window.location.href}
      />
      <motion.div
        key="season-episode-grid"
        className="w-100"
        initial="init"
        animate="show"
        exit="close"
        variants={divVariants}>
        <Section className="max-w-sm">
          <StoriesGrid />
        </Section>
      </motion.div>
    </>
  );
};

const StoriesGrid = () => {
  const { data } = useOutletContext();
  return (
    <div className="mt-3 py-4">
      {/*<h3 className="text-center">{data.longtitle}</h3>*/}

      <motion.div
        className="grid storygrid gap-0 gap-md-2"
        variants={container}
        style={{ originX: 0.5, originY: 0.5 }}>
        {Object.values(data.episodes).map((obj, i) => (
          <StoryItem episode={obj} key={i} />
        ))}
      </motion.div>
    </div>
  );
};

const StoryItem = ({ episode }) => {
  return (
    <motion.div
      className="g-col-4 g-col-md-3 text-center"
      variants={item}
      whileHover={{ zIndex: 10 }}>
      <motion.div
        whileHover={{
          scale: 1.4,
          transition: {
            type: "spring",
            bounce: 0.75,
          },
        }}
        whileTap={{ scale: 0.8 }}>
        <div className="btn-stories">
          <Link to={"../" + episode.alias}>
            <img src={episode.titleImage} alt="" className="w-100" />
          </Link>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SeasonStories;
