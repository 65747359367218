import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";

import "./index.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //  staleTime: 1000 * 60 * 60,
    },
  },
});

// MATOMO instance
const instance = createInstance({
  urlBase: "https://websters.swiss/trk/",
  siteId: 1,
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MatomoProvider value={instance}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </MatomoProvider>
    </QueryClientProvider>{" "}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
