import { motion } from "framer-motion";

const ButtonMaster = ({ buttonImage, className, ...props }) => {
  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.1 }}
      className={"btn-icon bg-transparent border-0 shadow-none" + className}
      {...props}>
      <img src={buttonImage} alt="" width={props.size === "lg" ? "64" : "32"} />
    </motion.button>
  );
};

export const ButtonNext = (props) => {
  const url = process.env.REACT_APP_BASE_URL + "assets/app/svg/button-next.svg";
  return <ButtonMaster buttonImage={url} {...props} />;
};

export const ButtonPrev = (props) => {
  const url = process.env.REACT_APP_BASE_URL + "assets/app/svg/button-prev.svg";
  return <ButtonMaster buttonImage={url} {...props} />;
};

export default ButtonNext;
