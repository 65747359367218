import React from "react";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import { InputText, InputTextArea } from "./contact_utils";
import Button from "react-bootstrap/Button";
import { RiMailCheckLine } from "react-icons/ri";

const LicenceForm = ({ params }) => {
  const [status, setStatus] = React.useState("form");
  const formRef = React.useRef(null);
  const _l = params.lexicon;

  React.useEffect(() => {
    // console.log("form Ref", new FormData(formRef.current));
    if (status === "sent") window.scrollTo(0, 0);
  }, [status]);

  const onSubmit = async (e) => {
    if (!formRef.current) return null;
    const formData = new FormData(formRef.current);
    console.log("submittin", formRef.current.name);
    formData.append("formName", formRef.current.name);
    formData.append("ctx", params.cultureKey);
    return await fetch(process.env.REACT_APP_API_URL + "/formit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    // alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("sending");

    onSubmit(e).then((res) => {
      if (res.code === 200) setStatus("sent");
      else setStatus("error");
    });
  };

  return (
    <>
      {(status === "form" || status === "sending") && (
        <>
          <h3 className="fs-2 title-box mb-n4 mt-4">
            <span className="dark">{_l.licenceTitle}</span>
          </h3>
          <div className="comic-box mx-n3 py-5">
            <p style={{ whiteSpace: "pre-wrap" }}>{_l.licenceDesc}</p>
          </div>
        </>
      )}
      <div className="panel-box mx-n3 py-4">
        <h3 className="fs-6 title-box mb-n4">
          <span className="dark rtleft">{_l.licenceForm}</span>
        </h3>
        {(status === "form" || status === "sending") && (
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            name="licence-form"
            className="my-5">
            <fieldset disabled={status === "sending"}>
              <InputText
                name="firstname"
                label={_l.firstname}
                defaultValue="Max"
              />
              <InputText
                name="lastname"
                label={_l.lastname}
                defaultValue="Webster"
              />
              <InputText
                name="organisation"
                label={_l.organisation}
                defaultValue="The Websters Ltd."
              />
              <InputText
                name="street"
                label={_l.street}
                defaultValue="Zukunftstrasse 44"
              />
              <InputText name="city" label={_l.city} defaultValue="2501 Biel" />

              <InputText
                name="country"
                label={_l.country}
                defaultValue="Max Webster"
              />
              <InputText
                name="tel"
                label={_l.tel}
                defaultValue="++41 58 123 45 56"
                type="phone"
              />
              <InputText
                name="email"
                label={_l.email}
                defaultValue="max@websters.swiss"
                type="email"
              />
              <InputTextArea
                name="message"
                label={_l.yourmessage}
                defaultValue=""
              />
              <Button
                size="lg"
                variant="primary text-white mb-5"
                type="submit"
                name="formtype"
                value="licence"
                disabled={status === "sending"}>
                {status === "sending" ? _l.sending : _l.submit}
              </Button>
            </fieldset>
          </Form>
        )}
        {status === "sent" && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className="text-center display-1 bg-success p-3 d-flex align-items-center justify-content-center text-white my-5">
              <RiMailCheckLine />
            </div>
            <p style={{ whiteSpace: "pre-wrap" }}>{_l.licenceSent}</p>
          </motion.div>
        )}
        {status === "error" && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {_l.formError}
          </p>
        )}
      </div>
    </>
  );
};

export default LicenceForm;
