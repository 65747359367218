import React from "react";
import { motion } from "framer-motion";
import { Link, useMatch } from "react-router-dom";
import Container from "react-bootstrap/Container";

const NavbarLogo = ({ lang }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL + "assets/stories/";
  const match = useMatch(`/s:seasonId/*`);
  const [logo, setLogo] = React.useState("default");

  React.useEffect(() => {
    // console.log("tu call combien de fois ");
    if (match === null) logo !== "default" && setLogo("default");
    else if (match["params"]["*"]) logo !== "season" && setLogo("season");
    else logo !== "none" && setLogo("none");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  React.useEffect(() => console.log(logo), [logo]);

  return (
    <div className="container-top">
      <Container className="max-w">
        {/* <AnimatePresence> */}
        <motion.div
          key={"page-title-" + logo}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}>
          {logo !== "none" && (
            <Link
              className="text-reset text-decoration-none"
              to={
                logo === "season" && match
                  ? `../s${match.params.seasonId}`
                  : `.`
              }>
              <img
                className="my-3"
                src={
                  logo === "season"
                    ? `${baseUrl + lang}/s${
                        match?.params.seasonId ?? 3
                      }/title.svg`
                    : `${baseUrl}common/default-title/websters_title_${lang}.svg`
                }
                alt=""
                height="50px"
              />
            </Link>
          )}
        </motion.div>
        {/* </AnimatePresence> */}
      </Container>
    </div>
  );
};

export default NavbarLogo;
