import { Helmet } from "react-helmet-async";
import rootcolors from "../_utils/rootColors";

const Helmetor = ({ title, desc, img, color, colorVar, ...props }) => {
  const themeColor = colorVar
    ? colorVar.replace(/-|w|color/g, "")
    : color
    ? color
    : false;

  if (themeColor) {
    return (
      <Helmet>
        <meta name="theme-color" content={`${rootcolors[themeColor]}`} />
        <body style={`background-color: ${rootcolors[themeColor]}`} />
      </Helmet>
    );
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={desc} />
      {/* <!-- OG --> */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={props.url} />
      <meta property="og:site_name" content={props.site_name} />

      {/* <!-- Twitter --> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={img} />
      {/* <meta name="twitter:site" content="@USERNAME" />
      <meta name="twitter:creator" content="@USERNAME" /> */}
    </Helmet>
  );
};

export default Helmetor;
