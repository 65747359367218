import { motion } from "framer-motion";

const parentVariants = {
  init: false,
  animate: { transition: { when: "beforeChildren" } },
};

const bgVariants = {
  init: { opacity: 0 },
  animate: { opacity: 1 },
};

const coverVariants = {
  init: { opacity: 0, scale: 0.9 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: { ease: "backInOut", delay: 0.4, duration: 0.8 },
  },
};

const colorsVariants = {
  init: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.1 } },
};

const SeasonBackground = ({ data, overlay }) => {
  // define season images in style object
  /*let hasOverlay = Object.keys(overlay).length > 0 ? true : false;
  let filter = "";

  // set filter type
  if (overlay.url) {
    if (overlay.url === "none") {
      //filter = "grayscale(100%) blur(6px)";
      filter = "brightness(1)";
    } else {
      filter = "brightness(1)";
    }
  }
*/
  // const style = {
  //   // filter: hasOverlay ? filter : " grayscale(0) blur(0)",
  //   // filter: hasOverlay ? filter : " brightness(1)",
  //   // transition: hasOverlay ? "1s filter ease-out" : ".4s filter ease-out",
  // };
  const bgColorsUrl = { backgroundImage: `url("${data.backgroundColors}")` };
  const bgUrl = { backgroundImage: `url("${data.backgroundImage}")` };
  const coverUrl = { backgroundImage: `url("${data.coverImage}")` };

  return (
    <motion.div
      className="position-absolute top-0 vh-100 vw-100 season-bg-wrapper"
      initial="init"
      animate="animate"
      variants={parentVariants}
      style={{ backgroundColor: `var(--${data.alias}-color)` }}>
      <motion.div
        className="season-bg season-bg-colors"
        variants={colorsVariants}
        style={{ ...bgColorsUrl }}></motion.div>
      <motion.div
        className="season-bg season-background"
        variants={bgVariants}
        style={{ ...bgUrl }}></motion.div>

      <motion.div
        className="season-bg season-cover"
        variants={coverVariants}
        style={{ ...coverUrl }}></motion.div>
    </motion.div>
  );
};

export default SeasonBackground;
